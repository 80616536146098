const Research1Text = (
  <div>
    <h1>Investigating Structural Dynamics:</h1>
    <p className = "individualResearchBody">
      Systematic improvements in the lifetime of quantum states necessitates a 
      holistic understanding of material structure. Through the use of advanced 
      microscopy and diffraction techniques, we are able to identify the presence 
      of defects and inhomogeneities down to the atomic scale in both crystalline 
      and amorphous solids. This involves techniques such as differential phase 
      contrast and fluctuation electron microscopy. Further, through the use of <i>in situ</i> specimen 
      holders that enable sample cooling down to cryogenic 
      temperatures, we are able to directly understand the structure associated with 
      these systems at their relevant operating temperatures. By combining this information
      with quantum measurements, we are able to directly link specific defects to 
      quantum decoherence in an effort to systematically improve performance.
    </p>
  </div>
);



const Research1Publication1 = (
  <div>
    Bafia, D.; <b>Murthy, A. A.</b>; Grassellino, A.; Romanenko, A., Oxygen Vacancies 
    in Niobium Pentoxide as a Source of Two-Level System Losses in Superconducting 
    Niobium. <i>Phys. Rev. App.</i> 22 (2),{" "}
    <b>2024</b>, 024035{" "}
    <a href="https://journals.aps.org/prapplied/abstract/10.1103/PhysRevApplied.22.024035">[HTML]</a>
    </div>
);

const Research1Publication2 = (
  <div>
    <b>Murthy, A. A.</b>; Das, P. M.; Ribet, S. M.; Kopas, C.; Lee, J.; Reagor,
    M. J.; Zhou, L.; Kramer, M. J.; Hersam, M. C.; Checchin, M.; Grassellino,
    A.; dos Reis, R.; Dravid, V. P.; Romanenko, A., Developing a Chemical and
    Structural Understanding of the Surface Oxide in a Niobium Superconducting
    Qubit.  <i>ACS Nano</i> 16 (10),{" "}     
    <b>2022</b>, 17257–17262{" "}
    <a href="https://doi.org/10.1021/acsnano.2c07913">[HTML] </a>
  </div>
);

export { Research1Text, Research1Publication1, Research1Publication2};
